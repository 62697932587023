import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import Container from "components/Container";
import Intro from "components/Intro";
import { SEO } from "components/SEO";

function NewsPost(props) {
	const post = props.data.markdownRemark;
	const { title, date } = post.frontmatter;

	return (
		<Layout pageName="policy">
			<SEO title={title} />

			<Container className="newspost-container">
				<Intro>
					<h1>{title}</h1>
					<p>{date}</p>
				</Intro>
				<div dangerouslySetInnerHTML={{ __html: post.html }} />
			</Container>
		</Layout>
	);
}

export default NewsPost;

export const query = graphql`
	query PostQuery($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			excerpt
			frontmatter {
				title
				date(formatString: "MMMM Do YYYY")
			}
		}
	}
`;
